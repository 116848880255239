'use client';

import {useEffect} from 'react';
import {useRouter} from 'next/navigation';
import {Center, Loader} from '@mantine/core';

import {Path} from '@/hooks/routing/path-constants';
import {useGetPath} from '@/hooks/routing/use-get-path';
import {useCurrentUser} from '@/providers/current-user-provider';
import {
  LocalStorageKeySuffix,
  generateLocalStorageKey,
  useLocalStorageState,
} from '@/hooks/use-local-storage-state/use-local-storage-state';

export default function Page() {
  const router = useRouter();
  const {getPath} = useGetPath();
  const {currentUser} = useCurrentUser({redirectTo: '/sign-in'});

  const [lastRootNetworkSlug] = useLocalStorageState<string | null>({
    key: generateLocalStorageKey(currentUser?.id, LocalStorageKeySuffix.LastRootNetwork),
    jsonEncode: true,
    initialValue: null,
  });

  // TODO Consider: This could become a server component if we remove the local storage check above,
  // and would be able to do a server side redirect instead of waiting for the client.
  useEffect(() => {
    if (currentUser && typeof lastRootNetworkSlug === 'string') {
      router.push(getPath(Path.NetworkHome, {slug: lastRootNetworkSlug}));
    } else if (currentUser && currentUser.defaultNetwork?.slug) {
      router.push(getPath(Path.NetworkHome, {slug: currentUser.defaultNetwork.slug}));
    }
  }, []); // eslint-disable-line

  return (
    <Center h="100%">
      <Loader type="bars" size="lg" />
    </Center>
  );
}
